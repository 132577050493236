@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-SemiBold.eot');
  src: url('/fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Montserrat-SemiBold.woff2') format('woff2'),
    url('/fonts/Montserrat-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-Medium.eot');
  src: url('/fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Montserrat-Medium.woff2') format('woff2'),
    url('/fonts/Montserrat-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-Regular.eot');
  src: url('/fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Montserrat-Regular.woff2') format('woff2'),
    url('/fonts/Montserrat-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-Light.eot');
  src: url('/fonts/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Montserrat-Light.woff2') format('woff2'),
    url('/fonts/Montserrat-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-ExtraLight.eot');
  src: url('/fonts/Montserrat-ExtraLight.eot?#iefix')
      format('embedded-opentype'),
    url('/fonts/Montserrat-ExtraLight.woff2') format('woff2'),
    url('/fonts/Montserrat-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-SemiBold.eot');
  src: url('/fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Montserrat-SemiBold.woff2') format('woff2'),
    url('/fonts/Montserrat-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-Medium.eot');
  src: url('/fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Montserrat-Medium.woff2') format('woff2'),
    url('/fonts/Montserrat-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-Regular.eot');
  src: url('/fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Montserrat-Regular.woff2') format('woff2'),
    url('/fonts/Montserrat-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-Light.eot');
  src: url('/fonts/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Montserrat-Light.woff2') format('woff2'),
    url('/fonts/Montserrat-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-ExtraLight.eot');
  src: url('/fonts/Montserrat-ExtraLight.eot?#iefix')
      format('embedded-opentype'),
    url('/fonts/Montserrat-ExtraLight.woff2') format('woff2'),
    url('/fonts/Montserrat-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

body {
  font-family: 'Montserrat', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

.center-text {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.reduce-gap {
  gap: 0px !important;
}

.placeholder-style::placeholder {
  color: #fcfcfc;
  opacity: 0.5;
}

.radio-container {
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-w16 .radiomark {
  width: 16px;
  height: 16px;
}

.radiomark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 50%;
}

.radio-container input:checked ~ .radiomark {
  background-color: #ea4842;
}

.radiomark:after {
  content: '';
  position: absolute;
  display: none;
}

.radio-container input:checked ~ .radiomark:after {
  display: block;
}

.radio-container .radiomark:after {
  top: 5px;
  left: 5px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: white;
}

.bid-status-card {
  width: 195px;
}

.text-bluish-cyan {
  color: #017aad;
  background: #e5f5fc;
}

.in-progress-badge {
  color: #e99400;
  background: #fdf4e5;
}

.submitted-badge {
  color: #29823b;
  background: #eaf3eb;
}

.expired-badge {
  color: #dc2020;
  background: #fceaea;
}

.bit-item-detail .shadow,
.bid-item-summary {
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
}

.shadow {
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
}

.align-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.phone-code-select .select2-selection {
  border-radius: 6px 0 0 6px;
}

.phone-code-select {
  border-right: 1px solid rgba(252, 252, 252, 0.5);
}

.phone-code-select .form-select__menu {
  color: #000;
}

.phone-code-select .form-select__control {
  background-color: rgba(252, 252, 252, 0.2);
}

.phone-code-select .form-select__indicator-separator {
  display: none;
}

input.phone-code-input {
  background-color: rgba(252, 252, 252, 0.2);
  color: #fff;
}

input.phone-code-input::placeholder,
.phone-code-select .form-select__input-container,
.phone-code-select .form-select__single-value,
.phone-code-select .form-select__input::placeholder,
.phone-code-select .form-select__placeholder {
  color: #fff;
}

.expired-bid,
.cancelled-order {
  color: #717171;
  background-color: transparent;
}

.expired-bid.bid-item-summary img,
.cancelled-order.bid-item-summary img {
  opacity: 0.6;
}

.expired-bid.bit-item-detail img,
.cancelled-order.bit-item-detail img {
  opacity: 0.6;
}

.expired-bid.bit-item-detail button,
.expired-bid.bid-cta button.to-rose,
.cancelled-order.bit-item-detail button,
.cancelled-order.bid-cta button.to-rose {
  background-color: #717171;
  color: #fff;
}

.expired-bid.bit-item-detail input,
.expired-bid.bit-item-detail textarea,
.expired-bid.bid-summary-organism textarea {
  background-color: #f3f3f6;
}

.overlayer {
  width: 100%;
  height: 100%;
  position: fixed;
  background: #222222cc;
}

div.form-select__dropdown-indicator {
  color: #fcfcfc;
  opacity: 0.5;
}

.form-select__control--is-focused div.form-select__dropdown-indicator {
  opacity: 1;
}

.page-not-found {
  color: '#075fc9';
}

.searchkit-list-menu .ais-Menu-item--selected .searchkit-list-label {
  color: #ea4842;
  font-weight: 500;
}

.min-w-5 {
  min-width: 1.25rem;
}

.searchkit-list-menu .ais-Menu-item--selected .searchkit-menu-list-count {
  background-image: linear-gradient(
    66.43deg,
    #e58023 -33.51%,
    #cf578f 94.65%,
    #e03d24 207.46%
  );
  color: #fff;
  text-align: center;
}

.h-50 {
  height: 12.5rem;
}

.max-w-48 {
  max-width: 12rem;
}

.max-w-56 {
  max-width: 14rem;
}

button.searchkit-load-more-button:hover,
button.searchkit-load-more-button:focus {
  background: linear-gradient(
    66.43deg,
    #e58023 -33.51%,
    #cf578f 94.65%,
    #e03d24 207.46%
  );
  border: none;
  outline: none;
  box-shadow: none;
  color: #fff;
}

.modal-backdrop.show {
  opacity: 0.8;
}

.modal-backdrop {
  z-index: 1;
  background: #000000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.h-5\/6 {
  height: 83.333333%;
}

.w-9\/12 {
  width: 75%;
}

.z-101 {
  z-index: 101;
}

.top-2\/4 {
  top: 50%;
}

.bid-request-modal {
  top: 20%;
  transform: translate(-50%, 0);
  left: 50%;
}

button:disabled,
button[disabled] {
  opacity: 0.6;
}

.searchkit-filter-container
  div.searchkit-clear-all-button-container
  button.searchkit-clear-all-button {
  background: transparent;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 12px;
  color: #ea4842;
  padding: 0px;
  height: auto;
}

.searchkit-filter-show-more-button {
  border-radius: 6px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  background: linear-gradient(
    90deg,
    #f38023 -90.01%,
    #dd579d 205.57%,
    #ee3d24 467.37%
  );
  height: 24px;
  padding: 4px 12px;
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  border: none;
}

.searchkit-filter-show-more-button:hover,
.searchkit-filter-show-more-button:focus {
  background: linear-gradient(
    90deg,
    #f38023 -90.01%,
    #dd579d 205.57%,
    #ee3d24 467.37%
  );
  border: none;
  box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.08);
}

.searchkit-search-box input {
  background: #fff;
  border-radius: 4px;
  height: 32px;
  border: 1px solid #e4e4e4;
  box-shadow: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #020202;
}

.searchkit-search-box input::placeholder {
  color: #bababa;
}

.searchkit-checkbox {
  z-index: 2;
  color: transparent;
  background: transparent;
}

.searchkit-checkbox:checked {
  z-index: 3;
  color: transparent;
  background: transparent;
}

.searchkit-selected-item label span:nth-child(even) {
  color: #000;
}

.searchkit-searchbox::before {
  background-size: contain;
  width: 24px;
  height: 24px;
  left: 13rem;
  top: 23px;
  z-index: 1;
}

.searchkit-searchbox input::placeholder {
  color: #bababa;
  font-weight: 500;
  line-height: 24px;
}

.searchkit-searchbox input {
  font-size: 16px;
  padding-left: 42px;
}

.searchkit-searchbox button {
  top: 1.6rem;
  right: 0rem;
}

.searchkit-filter-menu-item span::before {
  left: -4px;
  top: 5px;
  width: 20px;
  height: 20px;
  position: absolute;
  content: '';
}

.searchkit-filter-menu-item-checked:checked {
  left: -4px;
  top: 5px;
  width: 20px;
  height: 20px;
  position: absolute;
  z-index: 9;
  content: '';
}

.searchkit-delivery-menu-item span::before {
  left: 0px;
  top: -1px;
  width: 20px;
  height: 20px;
  position: absolute;
  content: '';
}

.searchkit-delivery-menu-item-checked:checked {
  left: 0px;
  top: -1px;
  width: 20px;
  height: 20px;
  position: absolute;
  z-index: 9;
  content: '';
}

.searchkit-delivery-menu-item .searchkit-filter-menu-item-checked:checked {
  left: 0px;
  top: -1px;
}

.searchkit-delivery-menu-item input:checked + span {
  padding-left: 13px !important;
}

li.searchkit-filter-menu-item input:checked + span {
  padding-left: 13px !important;
}

li.searchkit-filter-menu-item input:checked + span::before {
  background-repeat: no-repeat;
  content: '';
  z-index: 1;
}

.search-filter-modal {
  left: -8.5rem;
}

.searchkit-filter-menu-selected-item .searchkit-filter-menu-label {
  color: #020202;
}

div.searchkit-pagination li.searchkit-pagination-selected-link > a {
  border-radius: 2px !important;
  border: none;
  color: #fff;
  background: #ea4842;
  width: 24px;
  height: 28px;
  box-shadow: none;
}

.searchkit-pagination-link {
  border: none;
  background: transparent;
  box-shadow: none;
  font-size: 16px;
  font-weight: 500;
}

.searchkit-pagination-next-page,
.searchkit-pagination-previous-page {
  border: 1px solid #ededed;
}

.searchkit-pagination-next-page,
.searchkit-pagination-previous-page {
  display: flex;
  height: 36px;
  font-size: 14px;
  padding: 9px 16px 10px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #fff;
  font-weight: 500;
}

.searchkit-pagination-link:active {
  box-shadow: none;
  border: none;
}

.searchkit-pagination-previous-page .searchkit-pagination-link,
.searchkit-pagination-next-page .searchkit-pagination-link {
  background: #fff !important;
  padding: 9px 16px 10px 16px;
  color: #ea4842;
}

.searchkit-filter-container .react-datepicker-popper {
  transform: none !important;
  z-index: 50;
}

.react-datepicker-popper[data-placement^='top'] {
  padding-bottom: 25px !important;
}

.searchkit-filter-container .react-datepicker {
  border: 1px solid #f3f3f6;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
}

div.searchkit-datepicker-day {
  font-size: 12px;
  font-weight: 600;
  color: #ea4842;
}

div.react-datepicker__day--outside-month {
  color: #bababa;
}

div.react-datepicker__header {
  background: #fff;
  font-size: 12px;
  border-bottom: none;
}

.react-datepicker__triangle,
.react-datepicker-wrapper {
  display: none !important;
}

div.react-datepicker__current-month,
div.react-datepicker__day-name {
  font-weight: 600;
  font-size: 12px;
}

div.react-datepicker__day--selected,
div.searchkit-datepicker-selected-day,
div.react-datepicker__day:hover {
  border-radius: 100%;
  color: #fff;
  background: linear-gradient(
    66deg,
    #e58023 -33.51%,
    #cf578f 94.65%,
    #e03d24 207.46%
  );
}

.h-min-280 {
  min-height: 70rem;
}

.searchkit-sort-by-widget {
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #717171;
}

.searchkit-date-picker {
  display: none;
}

.searchkit-search-box-event-type {
  width: 18rem;
  padding-right: 10px;
}

span.searchkit-label-text {
  white-space: normal;
}

.text-nowrap {
  text-wrap: nowrap;
  white-space: nowrap;
}

.top-68 {
  top: 17rem;
}

div.searchkit-clear-all-button-container
  button.searchkit-clear-all-button-mobile,
div.searchkit-clear-all-button-container
  button.searchkit-clear-all-button-mobile:disabled {
  background-image: linear-gradient(
    90deg,
    #f38023 -90.01%,
    #dd579d 205.57%,
    #ee3d24 467.37%
  );
  padding: 4px 12px;
  align-items: center;
  border-radius: 6px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  text-wrap: nowrap;
  font-size: 14px;
  color: #fcfcfc;
  font-weight: 500;
}

.searchkit-mobile-modal-popup:focus-within:has(.ais-SearchBox-input:focus) {
  top: 55px;
  max-height: 100vh;
}

div.searchkit-clear-all-button-container button.searchkit-clear-button-mobile,
div.searchkit-clear-all-button-container
  button.searchkit-clear-button-mobile:disabled {
  background: none;
  color: #ea4842;
  border: none;
  font-weight: 500;
  font-size: 14px;
}

.searchkit-searchbox input:focus {
  outline: none;
  box-shadow: none;
}

.searchkit-mobile-modal-popup {
  left: -15rem;
}

.searchkit-sort-overlayer {
  display: none;
}

@media (min-width: 300px) and (max-width: 400px) {
  .searchkit-searchbox input {
    width: 18.5rem !important;
  }
}

.react-datepicker__navigation {
  top: 6px !important;
}

@media (min-width: 300px) and (max-width: 720px) {
  .react-datepicker__day,
  .react-datepicker__month-text,
  .react-datepicker__quarter-text,
  .react-datepicker__year-text {
    width: 2rem !important;
    line-height: 2rem !important;
    font-size: 12px;
  }

  .searchkit-datepicker-container div.searchkit-datepicker-day,
  .searchkit-datepicker-container div.react-datepicker__day-name {
    width: 2rem;
    line-height: 2rem;
    font-size: 15px;
  }

  .searchkit-datepicker-container div.react-datepicker__current-month {
    font-size: 15px;
  }

  .searchkit-mobile-modal-popup {
    left: 0;
    bottom: 0;
    z-index: 999;
  }

  .searchkit-sort-overlayer {
    display: block;
  }

  .searchkit-search-box-event-type {
    width: 100%;
  }

  .searchkit-searchbox::before {
    width: 21.368px;
    height: 20px;
    top: 17px;
    background-size: cover;
    left: 2rem;
  }

  .searchkit-searchbox input {
    padding-left: 35px;
  }

  .searchkit-searchbox input::placeholder {
    font-size: 14px;
  }

  div.react-datepicker__month-container {
    box-shadow: 0px 2px 8px 0px #00000014;
  }

  div.react-datepicker-popper {
    transform: translate3d(2%, -238.5px, 0px) !important;
    background: #fff;
    z-index: 99 !important;
    top: -80px !important;
    margin: 10px auto;
  }

  .searchkit-range-selector {
    margin-bottom: 30px;
  }

  div.react-datepicker {
    border: none;
  }

  form.searchkit-searchbox button {
    top: 1.2rem;
    right: -1rem;
  }

  .selected-menu-tab .searchkit-list-label {
    color: #ea4842 !important;
    font-weight: 500 !important;
  }

  .selected-menu-tab .searchkit-menu-list-count {
    background-image: linear-gradient(
      66.43deg,
      #e58023 -33.51%,
      #cf578f 94.65%,
      #e03d24 207.46%
    );
    color: #fff !important;
    text-align: center;
  }

  .selected-menu-tab {
    border-bottom: 2px solid #ea4842 !important;
    background-image: none !important;
  }
}

.bg-stats-home {
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}

.searchkit-searchbox button {
  top: 1.6rem;
  right: 1rem;
  position: absolute;
  color: #d1d1db;
  fill: #d1d1db;
}

.searchkit-range-selector .rc-slider-track {
  background-image: linear-gradient(
    66.43deg,
    #e58023 -33.51%,
    #cf578f 94.65%,
    #e03d24 207.46%
  );
  height: 8px;
}

.searchkit-range-selector .rc-slider-rail {
  height: 8px;
}

.searchkit-range-selector .rc-slider-handle {
  border: 1px solid #e58023;
  width: 20px;
  height: 20px;
  background-color: #fff;
  opacity: 1;
}

.z-2 {
  z-index: 2;
}

.expired-bid .radio-container input:checked ~ .radiomark {
  background-color: #a3a3a3;
}

.selected-menu-tab .searchkit-list-label {
  color: #fff;
  font-weight: 600;
}

.selected-menu-tab .searchkit-menu-list-count {
  color: #e03d24;
  text-align: center;
  min-width: 25px;
}

.selected-menu-tab {
  background-image: linear-gradient(
    66.43deg,
    #e58023 -33.51%,
    #cf578f 94.65%,
    #e03d24 207.46%
  );
  border-radius: 12px;
}

.flex-percentage {
  flex: 0 0 100%;
}

.searchkit-mobile-count {
  padding-top: 1px;
}

.text-misty-moss {
  color: #ffca39;
}

.text-light-silver {
  color: #d4d6d9;
}

.text-royal-orange {
  color: #f78c3e;
}

.searchkit-search-box-event-type .ais-RefinementList-label {
  display: -webkit-inline-box;
}

.searchkit-search-box-event-type {
  overflow-x: hidden;
}

.searchkit-checkbox {
  display: block;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.bg-planner-cart-bg .ais-Highlight-highlighted {
  color: #fff;
}

.-mt-13 {
  margin-top: -3rem;
}

.overflow-auto {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.overflow-auto::-webkit-scrollbar {
  display: none;
}

.searchkit-radio {
  position: relative;
  left: 2px;
  top: 1px;
}

.phone-code-select-login .form-select__control {
  background: transparent;
  color: #fff;
  height: 24px;
  min-height: 33px;
  border-bottom: 1px solid;
  border-radius: 0;
}

.phone-code-select-login .css-1u9des2-indicatorSeparator {
  background-color: transparent;
}

.phone-code-select-login .form-select__single-value {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.phone-code-select-login .form-select__menu {
  color: #000;
}

input.phone-code-input::placeholder,
.phone-code-select-login .form-select__input-container,
.phone-code-select-login .form-select__single-value,
.phone-code-select-login .form-select__input::placeholder,
.phone-code-select-login .form-select__placeholder {
  color: #fff;
}

div.css-p6co64-control:hover {
  border-bottom: 1px solid #fff;
}

.-mt-35 {
  margin-top: -33rem !important;
}

.searchkit-radio {
  width: 1px;
  height: 1px;
}

.empty-radio-span {
  width: 14px;
}

.navigation-humburger-menu li a:hover .menu-item {
  background: linear-gradient(
    66.43deg,
    #e58023 -33.51%,
    #cf578f 94.65%,
    #e03d24 207.46%
  );
}

.navigation-humburger-menu li a:hover div {
  color: #fff;
}

.white-icon {
  display: none;
}

.navigation-humburger-menu li a:hover .white-icon {
  display: block;
}

.navigation-humburger-menu li a:hover .red-icon {
  display: none;
}

.react-datepicker__triangle,
.react-datepicker-wrapper {
  display: inline-block !important;
}

.searchkit-datepicker .react-datepicker__input-container {
  color: #020202;
  font-size: 14px;
  font-weight: 500;
  background-color: #fcfcfc;
  border: 1px solid #e4e4e4;
  padding: 3px 0px 3px 30px;
  border-radius: 5px;
}

.react-datepicker-ignore-onclickoutside:focus-visible {
  outline: none;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle {
  display: none !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background: linear-gradient(
    66deg,
    #e58023 -33.51%,
    #cf578f 94.65%,
    #e03d24 207.46%
  ) !important;
  border-radius: 100% !important;
}

.ais-CurrentRefinements-delete {
  border: 1px solid #bababa;
  border-radius: 100%;
  width: 16px;
  height: 16px;
  line-height: 14.9px;
}

.react-datepicker__input-container input:focus-visible {
  outline: none;
}

b,
strong {
  font-weight: bold;
}

.carousel-modal-image img {
  object-fit: contain;
}

.carousel .thumbs-wrapper {
  display: none !important;
}
